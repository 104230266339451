import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="not-found-title">Page Not Found</h1>
    <p className="not-found-text">
      You just hit a page that doesn&#39;t exist...
    </p>
  </Layout>
);

export default NotFoundPage;
